.container {
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
  width: 100%;
  @media (min-width: 576px) {
    max-width: 540px;
  }
  @media (min-width: 768px) {
    max-width: 720px;
  }
  @media (min-width: 992px) {
    max-width: 960px;
  }
  @media (min-width: 1200px) {
    max-width: 1140px;
  }
  @media(min-width: 1280px) {
    max-width: 1280px;
  }
}

p{
  margin: 0;
}

body {
  position: relative;
  margin: 0;
  overflow: hidden;
  -webkit-font-smoothing: antialiased;
  overscroll-behavior: none;

  &:after {
    content: "";
    position: absolute;
    top: 98px;
    left: 0;
    width: 100%;
    height: calc(100% - 98px);
    background: white;
    z-index: 1;
    opacity: 0;
    pointer-events: none;
  }

  &.new-page {
    &:after {
      animation: pageFadeOut .6s ease-in forwards;
    }
  }
}

#root {
  & > div {
    height: 100%;
  }
}

@keyframes pageFadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

body, html, #root, .main-page-wrapper {
  height: 100%;
}

.main-page-wrapper {
  overflow-y: auto;
  overflow-x: hidden;
  -webkit-overflow-scrolling: touch;
}

.page-wrapper {
  .section-wrapper {
    position: relative;
    background: white;
    z-index: 1;
    padding-bottom: 200px;
    min-height: 100vh;
    @media(max-width: 991px) {
      padding-bottom: 160px;
    }
    @media(max-width: 480px) {
      padding-bottom: 80px;
    }
  }
}

.form-group {
  position: relative;

  &.textarea {
    margin-top: 30px;
    @media(max-width: 480px) {
      margin-top: 0;
    }

    i {
      left: 16px;
      @media(max-width: 480px) {
        left: 14px;
      }
    }
  }

  i {
    position: absolute;
    top: 46px;
    left: 22px;
    font-size: 18px;
    @media(max-width: 480px) {
      left: 15px;
    }
    &.icon-email{
      @media not all and (min-resolution: .001dpcm) {
        @supports (-webkit-appearance:none) {
          top: 47px;
        }
      }
    }
  }

  .control-label {
    display: block;
    color: #808599;
    font-size: 12px;
    letter-spacing: -0.25px;
    line-height: 18px;
    margin-bottom: 8px;
  }

  .form-control {
    border: 1px solid #E0E4E7;
    border-radius: 5px;
    height: 60px;
    background-clip: padding-box;
    padding: .375rem .75rem;
    padding-left: 40px;
    width: 100%;
    line-height: 14px;
    font-size: 14px;
    display: block;
    box-sizing: border-box;
    font-family: termina, sans-serif;
    transition: all .3s ease-in-out;
    @media(max-width: 991px) {
      font-size: 12px;
      line-height: 12px;
    }

    &:focus {
      border-color: $black;
      outline: none;

    }

    &::placeholder {
      color: #808599;
      font-size: 14px;
      font-weight: 500;
      letter-spacing: -0.8px;
      line-height: 14px;
      @media not all and (min-resolution: .001dpcm) {
        @supports (-webkit-appearance:none) {
          position: relative;
          top: 4px;
          @media(max-width: 480px){
            top: 2px;
          }
        }
      }
      @media(max-width: 991px) {
        font-size: 12px;
        line-height: 14px;
      }
    }
  }

  textarea.form-control {
    height: auto;
    resize: none;
    padding-top: 18px;
    line-height: 20px;
  }

  .validation-alert {
    position: absolute;
    bottom: -6px;
    background: white;
    right: 18px;
    font-size: 10px;
    color: $red;
    letter-spacing: .3px;
    padding: 0 8px;
    z-index: 9999;

    + .form-control {
      border-color: $red;

      &:focus {
        border-color: $red;

        ~ i {
          &:before {
            color: $red;
          }
        }
      }
    }

    ~ i {
      &:before {
        color: $red;
      }
    }
  }
}


.lemon-cursor {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 13;
  pointer-events: none;
  font-size: 12px;
  opacity: 0;
  transition: opacity .3s ease-in-out;

  &:before {
    content: "";
    display: block;
    background: black;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    transition: transform .3s ease-in-out;
  }

  .video-circle {
    width: 250px;
    height: 250px;
    border-radius: 100%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    overflow: hidden;
    transition: all .2s ease-in-out;
    animation: scaleUp .5s forwards;


    video {
      min-width: 100%;
      min-height: 100%;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      z-index: -1;

    }
  }

  &.default-hovered {
    mix-blend-mode: difference;

    &:before {
      transform: scale(8);
      background: white;
    }
  }

  &.photo-hovered {
    &:before {
      background: rgba(black, 0.5);
      transform: scale(9);
    }

    &:after {
      content: "Zobacz";
      display: block;
      color: white;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);

    }
  }

  &.loaded {
    opacity: 1;

    &:before {
      width: 10px;
      height: 10px;
    }
  }

  &.closing-video-circle {
    .video-circle {
      animation: scaleDown .2s forwards;
    }
  }

  &.d-none {
    display: none;
  }
}

@keyframes scaleUp {
  0% {
    width: 0;
    height: 0;
  }
  100% {
    width: 250px;
    height: 250px;
  }
}

@keyframes scaleDown {
  0% {
    width: 250px;
    height: 250px;
  }
  100% {
    width: 0;
    height: 0;
  }
}

.fadeInUp {
  animation: fadeInUp .4s forwards;
}

@keyframes fadeInUp {
  0% {
    transform: translateY(-50px);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

.relative {
  position: relative;
}

.overflow-hidden {
  overflow: hidden;
}

*, ::after, ::before {
  box-sizing: border-box;
}

.fade-exit, .fade-enter-done,.main-page-wrapper {
  position: relative;

  &:after {
    content: "";
    position: fixed;
    z-index: 12;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: white;
    opacity: 0;
    pointer-events: none;
    animation: fadeEnter .5s ease-in-out forwards;
  }
}

.fade-enter {
  opacity: 0;
  height: 0;
}

.fade-enter-done{
  &:after {
    opacity: 1;
    animation: fadeExit .5s ease-in-out forwards;
  }
}

.fade-exit{
  &:after{
    pointer-events: visible;
  }
}

.main-page-wrapper{
  &:after {
    opacity: 1;
    animation: fadeExit 1s ease-in-out forwards;
    animation-delay: 0.5s;
  }
}

@keyframes fadeEnter {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes fadeExit {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.swiper-container .swiper-pagination{
  .swiper-pagination-bullet.swiper-pagination-bullet-active{
    background-color: $yellow;
  }
}
