@font-face {
  font-family: 'icomoon';
  src: url('/fonts/icomoon.eot?27g1js');
  src: url('/fonts/icomoon.eot?27g1js#iefix') format('embedded-opentype'),
  url('/fonts/icomoon.ttf?27g1js') format('truetype'),
  url('/fonts/icomoon.woff?27g1js') format('woff'),
  url('/fonts/icomoon.svg?27g1js#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-arrow {
  &:before {
    content: "\e900";
  }

  &.right {
    transform: rotate(90deg);
  }
  &.left {
    transform: rotate(-90deg);
  }
}

.icon-cross:before {
  content: "\e901";
}

.icon-email:before {
  content: "\e902";
  color: #fee529;
}

.icon-facebook:before {
  content: "\e903";
}

.icon-gps:before {
  content: "\e904";
}

.icon-instagram:before {
  content: "\e905";
}

.icon-profile:before {
  content: "\e906";
  color: #fee529;
}

.icon-write:before {
  content: "\e907";
  color: #fee529;
}

.icon-youtube:before {
  content: "\e908";
}
.icon-x:before {
  content: "\e909";
}
.icon-tick:before {
  content: "\e90a";
}

.icon-vimeo:before {
  content: "\eaa0";
}
